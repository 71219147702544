import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useState } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PURGE } from 'redux-persist';
import tw from 'twin.macro';
import * as yup from 'yup';

import { useMyDocumentsAuthMutation, useMyDocumentsSendOtpMutation } from '@/app/api/my-documents';
import { setAuth } from '@/app/store/slices/auth';
import { getChannel } from '@/app/store/slices/channel';
import TickIcon from '@/assets/images/icons/TickIcon';
import { BigTitle, BodyText, Button, SubBody, SubTitle, Title } from '@/components';
import { ThemeContext } from '@/contexts/theme-context';
import LoginContent from '@/views/login';
import BigExclamationIcon from '@/assets/images/icons/BigExclamationIcon';

function MyDocumentsAuth() {
  const { theme, setTheme } = useContext(ThemeContext);
  const [countryFormatLength, setCountryFormatLength] = useState(11);
  const channel = useSelector(getChannel);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isExeceptionCountry, setIsExeceptionCountry] = useState(false);
  const { t } = useTranslation();
  const schema = yup
    .object({
      mobile_phone: yup
        .string()
        .trim()
        .test('len', '', val => {
          if (isExeceptionCountry) {
            if (val.length !== countryFormatLength && val.length !== countryFormatLength + 1) {
              return false;
            } else {
              return true;
            }
          } else {
            return String(val).length === countryFormatLength && !(String(val).length > countryFormatLength);
          }
        })
        .required()
    })
    .required();

  const {
    control,
    setValue,
    watch,
    resetField,
    getValues,
    setError,
    formState: { isValid, errors }
  } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

  const mobile_phone = watch('mobile_phone');
  const code = watch('code');
  const [fetchSendOtp, { isSuccess: sendOtpSuccess, error: sendOtpError, isLoading: sendOtpLoading }] =
    useMyDocumentsSendOtpMutation();
  const [fetchApplyAuth, { isSuccess: applyAuthSuccess, error: applyAuthError, data, isLoading: applyAuthLoading }] =
    useMyDocumentsAuthMutation();

  const sendOtp = useCallback(() => {
    let phone = '';
    if (!mobile_phone?.includes('+')) {
      phone = '+' + mobile_phone?.replaceAll(' ', '');
    } else {
      phone = mobile_phone?.replaceAll(' ', '');
    }

    if (phone?.length >= 12 && !sendOtpSuccess) {
      fetchSendOtp({ mobile_phone: phone });
    }
  }, [mobile_phone, applyAuthSuccess, fetchSendOtp]);

  const handleResendOtp = useCallback(() => {
    const phone = '+' + mobile_phone?.replaceAll(' ', '');
    fetchSendOtp({ mobile_phone: phone });
  }, [mobile_phone, fetchSendOtp]);

  useEffect(() => {
    if (sendOtpError?.data?.error?.code === 'INVALID_OTP') {
      setError('code', { type: 'value', message: sendOtpError?.data?.error?.description });
    }
    if (sendOtpError?.data?.error?.code === 'NO_ACTIVE_ACCOUNT') {
      setError('mobile_phone', { type: 'value', message: sendOtpError?.data?.error?.description });
    }
    if (sendOtpError?.data?.error?.code === 'NO_ACTIVE_CARD') {
      setError('mobile_phone', { type: 'value', message: sendOtpError?.data?.error?.description });
    }
  }, [sendOtpError, setError]);

  useEffect(() => {
    if (code?.length === 4) {
      if (mobile_phone) {
        const withPlus = '+' + mobile_phone;
        document.cookie = `channel=${channel}; max-age=300`;
        fetchApplyAuth({ code, mobile_phone: withPlus });
      }
    }
  }, [code, mobile_phone, fetchApplyAuth]);

  useEffect(() => {
    dispatch({ type: PURGE, result: () => null });
  }, [dispatch]);

  useEffect(() => {
    if (applyAuthError) {
      setError('code', { type: 'value', message: applyAuthError?.data?.error?.description });
    }
  }, [applyAuthError, setError]);

  useEffect(() => {
    if (applyAuthSuccess) {
      dispatch(setAuth(data?.data));
      navigate('/my-documents-main');
    }
  }, [applyAuthSuccess]);

  const translate = line => {
    return t(`my_documents_auth.${line}`);
  };
  const benefits = useMemo(
    () => [
      translate('functionality.first'),
      translate('functionality.second'),

      {
        text: translate('functionality.third.title'),
        stickerText: translate('functionality.third.text')
      },
      translate('functionality.fourth'),
      translate('functionality.fifth')
    ],
    []
  );

  useEffect(() => {
    setTheme('dark');
  }, [setTheme]);

  return (
    <div>
      <div tw='flex flex-col gap-5 max-w-[416px] mx-auto'>
        <div tw='w-[144px] h-[144px] flex items-center justify-center rounded-full mx-auto bg-yellow-alpha20 mt-[48px]'>
          <BigExclamationIcon />
        </div>
        <p tw='text-s18 text-center'>
          Уважаемый клиент, для выгрузки документов необходимо перейти в мобильное приложение Freedom Banker
        </p>
        <Button variant={'secondary'} onClick={() => window.location.assign('https://api.bankffin.kz/app/to')}>
          Перейти
        </Button>
      </div>
    </div>
  );
}

export default MyDocumentsAuth;
